import {afterNextRender,Component,Inject} from '@angular/core';
import {HeaderComponent} from './misc/components/header/header.component';
import {DrawerComponent} from './misc/components/drawer/drawer.component';
import {SharedModule} from './shared/shared.module';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';

@Component({
	selector:'app-root',
	imports:[
		SharedModule,
		HeaderComponent,
		DrawerComponent
	],
	templateUrl:'./app.component.html',
	styleUrl:'./app.component.scss'
})
export class AppComponent{
	constructor(
		title:Title,
		@Inject(DOCUMENT) document:Document
	){
		title.setTitle('Bendita Administrator');
		
		afterNextRender(():void=>{
			document.defaultView?.scrollTo(0,0);
		});
	}
	
	
}
