import {Component,DestroyRef,inject,input,InputSignal} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../../app.service';
import {SharedModule} from '../../../shared/shared.module';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {BreakpointObserver,Breakpoints,BreakpointState} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatIcon} from '@angular/material/icon';
import {MatListItemIcon,MatListItemTitle} from '@angular/material/list';
import {CUSTOM_BREAKPOINT} from '../../../common/enums/custom-breakpoint.enum';

@Component({
	selector:'app-header',
	imports:[
		SharedModule,
		MatIcon,
		MatListItemIcon,
		MatListItemTitle
	],
	templateUrl:'./header.component.html',
	styleUrl:'./header.component.scss'
})
export class HeaderComponent{
	drawer:InputSignal<MatDrawer>=input.required();
	appService:AppService=inject(AppService);
	authenticationService:AuthenticationService=inject(AuthenticationService);
	destroyRef:DestroyRef=inject(DestroyRef);
	breakpointObserver:BreakpointObserver=inject(BreakpointObserver);
	isXSmallWindow:Observable<BreakpointState>=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	isXXSmallWindow:Observable<BreakpointState>=this.breakpointObserver.observe([CUSTOM_BREAKPOINT.XXSmall])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	
	
}
