<mat-toolbar class="drawer-header">
	<button mat-icon-button type="button" (click)="drawer().toggle()" aria-label="Close">
		<mat-icon class="app-24">close</mat-icon>
	</button>
	<div class="spacer"></div>
	@if (appService.theme(); as theme) {
		<button mat-icon-button type="button" (click)="appService.toggleDarkTheme()" aria-label="Toggle dark mode" [matTooltip]="theme==='app-dark-theme' ? 'Light mode' : 'Dark mode'">
			<mat-icon>{{theme==='app-dark-theme' ? 'light_mode' : 'dark_mode'}}</mat-icon>
		</button>
	} @else {
		<button mat-icon-button type="button" (click)="appService.toggleDarkTheme()" aria-label="Toggle dark mode" [matTooltip]="matchesDark() ? 'Light mode' : 'Dark mode'">
			<mat-icon>{{matchesDark() ? 'light_mode' : 'dark_mode'}}</mat-icon>
		</button>
	}
</mat-toolbar>
<div class="drawer-content">
	<mat-nav-list *ngIf="authenticationService.user | async">
		<a mat-list-item [routerLink]="['/admin']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()" class="mat-elevation-z0">
			<span>Home</span>
		</a>
		<a mat-list-item [routerLink]="['/admin/users']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()" class="mat-elevation-z0">
			<span>Users</span>
		</a>
		<a mat-list-item [routerLink]="['/admin/products']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()" class="mat-elevation-z0">
			<span>Products</span>
		</a>
		<a mat-list-item [routerLink]="['/admin/blog-posts']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()" class="mat-elevation-z0">
			<span>Blog posts</span>
		</a>
		<a mat-list-item [routerLink]="['/admin/orders']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()" class="mat-elevation-z0">
			<span>Orders</span>
		</a>
		<a mat-list-item [routerLink]="['/admin/contact-requests']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="drawer().toggle()" class="mat-elevation-z0">
			<span>Contact requests</span>
		</a>
	</mat-nav-list>
</div>
