import {inject,Injectable} from '@angular/core';
import {HttpClient,HttpErrorResponse} from '@angular/common/http';
import {LoginRequestDto} from './dto/login-request.dto';
import {BehaviorSubject,catchError,firstValueFrom,Observable,switchMap,tap,throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {UserResponseDto} from './dto/user-response.dto';
import {DOCUMENT} from '@angular/common';

@Injectable({
	providedIn:'root'
})
export class AuthenticationService{
	private readonly document:Document=inject(DOCUMENT);
	private httpClient:HttpClient=inject(HttpClient);
	private cookieService:CookieService=inject(CookieService);
	readonly userSource:BehaviorSubject<UserResponseDto|null|undefined>=new BehaviorSubject<UserResponseDto|null|undefined>(undefined);
	user:Observable<UserResponseDto|null|undefined>=this.userSource.asObservable();
	
	constructor(){
		firstValueFrom(this.getUser()).then().catch((error)=>console.error(error.message));
	}
	
	getUser():Observable<UserResponseDto>{
		return this.httpClient.get<UserResponseDto>(`${environment.apiServer.url}/api/authentication/user`,{withCredentials:true})
		.pipe(
			tap((response:UserResponseDto):void=>{
				this.userSource.next(response);
			}),
			catchError((error:HttpErrorResponse)=>{
				this.userSource.next(null);
				return throwError(()=>error);
			})
		);
	}
	
	login(data:LoginRequestDto):Observable<UserResponseDto>{
		return this.httpClient.post<UserResponseDto>(`${environment.apiServer.url}/api/authentication/login`,data,{withCredentials:true})
		.pipe(
			switchMap(this.getUser.bind(this))
		);
	}
	
	logout():void{
		this.httpClient.post(`${environment.apiServer.url}/api/authentication/logout`,{},{withCredentials:true})
		.pipe(
			tap(():void=>{
				this.document.location.reload();
			}),
			catchError((error:HttpErrorResponse)=>{
				setTimeout(()=>{
					this.document.location.reload();
				},200);
				return throwError(()=>error);
			})
		)
		.subscribe({next:console.log,error:console.error});
	}
}
