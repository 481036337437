import {CanActivateChildFn,CanActivateFn,CanMatchFn,Router,UrlTree} from '@angular/router';
import {catchError,filter,firstValueFrom,map,Observable,of} from 'rxjs';
import {inject} from '@angular/core';
import {USER_ROLE} from '../common/enums/user-role.enum';
import {UserResponseDto} from '../authentication/dto/user-response.dto';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from '../authentication/authentication.service';

export const adminGuardActivate:CanActivateFn=():Promise<boolean|UrlTree>=>{
	const router:Router=inject(Router);
	return firstValueFrom(inject(AuthenticationService).user.pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):boolean|UrlTree=>{
			if(!user){
				return router.parseUrl('/');
			}else{
				if(user.role?.id!==USER_ROLE.Admin){
					return router.parseUrl('/error#FORBIDDEN');
				}else{
					return true;
				}
			}
		}),
		catchError((error:HttpErrorResponse):Observable<boolean>=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

export const adminGuardMatch:CanMatchFn=async():Promise<boolean|UrlTree>=>{
	const router:Router=inject(Router);
	return firstValueFrom(inject(AuthenticationService).user.pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):boolean|UrlTree=>{
			if(!user){
				return router.parseUrl('/');
			}else{
				if(user.role?.id!==USER_ROLE.Admin){
					return router.parseUrl('/error#FORBIDDEN');
				}else{
					return true;
				}
			}
		}),
		catchError((error:HttpErrorResponse):Observable<boolean>=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

export const adminGuardActivateChild:CanActivateChildFn=async():Promise<boolean|UrlTree>=>{
	const router:Router=inject(Router);
	return firstValueFrom(inject(AuthenticationService).user.pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):boolean|UrlTree=>{
			if(!user){
				return router.parseUrl('/');
			}else{
				if(user.role?.id!==USER_ROLE.Admin){
					return router.parseUrl('/error#FORBIDDEN');
				}else{
					return true;
				}
			}
		}),
		catchError((error:HttpErrorResponse):Observable<boolean>=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

