import {effect,inject,Injectable,signal,WritableSignal} from '@angular/core';
import {firstValueFrom,map,Observable} from 'rxjs';
import {ApolloQueryResult} from '@apollo/client/core';
import {LocalStorageService} from 'ngx-webstorage';
import {GetInitialDataQuery,GetInitialDataGQL} from './common/graphql/get-initial-data.generated';
import {DOCUMENT} from '@angular/common';

@Injectable({
	providedIn:'root'
})
export class AppService{
	private document:Document=inject(DOCUMENT);
	private localStorageService:LocalStorageService=inject(LocalStorageService);
	private readonly getInitialDataGQL:GetInitialDataGQL=inject(GetInitialDataGQL);
	theme:WritableSignal<string|null>=signal(this.localStorageService.retrieve('theme-id'));
	
	constructor(){
		effect(()=>{
			this.document.documentElement.classList.remove('app-dark-theme');
			this.document.documentElement.classList.remove('app-light-theme');
			const theme:string|null=this.theme();
			if(theme){
				this.localStorageService.store('theme-id',theme);
				this.document.documentElement.classList.add(theme);
			}
		});
		
		firstValueFrom(this.getInitialData()).then().catch((error)=>console.error(error));
		
	}
	
	toggleDarkTheme():void{
		switch(this.theme()){
			case 'app-light-theme':
				this.theme.set('app-dark-theme');
				break;
			case 'app-dark-theme':
				this.theme.set('app-light-theme');
				break;
			case null:
				this.theme.set(this.document.defaultView?.matchMedia('(prefers-color-scheme: dark)').matches ? 'app-light-theme' : 'app-dark-theme');
				break;
		}
	}
	
	getInitialData():Observable<GetInitialDataQuery>{
		return this.getInitialDataGQL
		.fetch({},{})
		.pipe(
			map((response:ApolloQueryResult<GetInitialDataQuery>):GetInitialDataQuery=>{
				return response.data;
			})
		);
	}
	
}
