@if (serverError(); as error) {
	<div class="form-message-text error">
		@if (error.graphQLErrors; as errors) {
			@if (errors.slice()?.shift()?.extensions?.response; as response) {
				Error: {{response.message?.toString()}}
			} @else {
				Error: {{errors.slice()?.shift().message}}
			}
		} @else if (error.error) {
			@if (error.error.message) {
				Error: {{error.error.message}}
			} @else {
				{{error.error.toString()}}
			}
		} @else {
			{{error.toString()}}
		}
	</div>
}
@if (serverResponse(); as response) {
	<div class="form-message-text center">
		{{response.toString()}}
	</div>
}
