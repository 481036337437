import {afterNextRender,Component,inject,Inject,signal,WritableSignal} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication/authentication.service';
import {FormControl,FormGroup,Validators} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {InputEmptyToNullDirective} from '../misc/directives/input-empty-to-null/input-empty-to-null.directive';
import {FormMessageComponent} from '../misc/components/form-message/form-message.component';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';

@Component({
	selector:'app-index',
	imports:[
		SharedModule,
		InputEmptyToNullDirective,
		FormMessageComponent
	],
	templateUrl:'./index.component.html',
	styleUrl:'./index.component.scss'
})
export class IndexComponent{
	private router:Router=inject(Router);
	private authenticationService:AuthenticationService=inject(AuthenticationService);
	email:FormControl=new FormControl(undefined,[
		Validators.required,
		Validators.email
	]);
	password:FormControl=new FormControl(undefined,[
		Validators.required
	]);
	form:FormGroup=new FormGroup({
		email:this.email,
		password:this.password
	});
	serverError:WritableSignal<any>=signal(null);
	serverResponse:WritableSignal<any>=signal(null);
	submitButtonActive:WritableSignal<boolean>=signal(true);
	
	constructor(
		title:Title,
		@Inject(DOCUMENT) document:Document
	){
		title.setTitle('Bendita Administrator - Login');
		
		afterNextRender(():void=>{
			document.defaultView?.scrollTo(0,0);
		});
	}
	
	login():void{
		if(this.submitButtonActive()){
			if(!this.form.valid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive.set(false);
				this.serverError.set(null);
				this.serverResponse.set(null);
				const next=async():Promise<void>=>{
					this.submitButtonActive.set(true);
					await this.router.navigate(['/admin']);
				};
				const error=(error:any):void=>{
					this.submitButtonActive.set(true);
					this.serverError.set(error);
				};
				this.authenticationService.login({
					email:this.form.get('email')?.value,
					password:this.form.get('password')?.value
				})
				.subscribe({next,error});
			}
		}
	}
	
	
}
