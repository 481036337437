import {Component,input,InputSignal} from '@angular/core';

@Component({
	selector:'app-form-message',
	imports:[],
	templateUrl:'./form-message.component.html',
	styleUrl:'./form-message.component.scss'
})
export class FormMessageComponent{
	serverError:InputSignal<any>=input();
	serverResponse:InputSignal<any>=input();
}
